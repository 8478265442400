import React from "react";
import { ImageContainer, IMG, Caption } from "./styles";

const Slide = ({ project, image }) => {
  const imgSrc = "/assets/images/slide_" + image.file;
  const altText = project.properties.title;
  const caption = image.caption;

  return (
    <>
      <ImageContainer>
        <IMG src={imgSrc} altalt={altText} />
      </ImageContainer>
      <Caption>{caption}</Caption>
    </>
  );
};

export default Slide;
