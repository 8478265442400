import styled from "styled-components";

export const Input = styled.input`
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #fff;

  margin-bottom: 20px;
  padding-bottom: 5px;
  width: 100%;
  max-width: 400px;
`;

export const Label = styled.label`
  position: relative;
  width: 100%;

  .lable {
    transform: translateY(16px);
    opacity: 0;
  }
`;

export const Submit = styled.input`
  display: block;
  margin-top: 20px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 10px 15px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: #fff;

  :active {
    transform: scale(0.9);
  }
`;
