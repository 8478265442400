import styled from "styled-components";

export const SlideContainer = styled.div`
  position: relative;
  display: block;
`;

export const ButtonContainer = styled.div`
  padding: 18px 30px 20px 0;
  float: right;
  text-align: center;

  > * {
    padding: 5px 0;
  }

  @media (max-width: 500px) {
    float: unset;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 7.5vw;
  }
`;

export const Button = styled.button`
  font-size: 12px;

  background-color: transparent;
  /* padding: 1rem 0; */
  border: none;
  border-radius: 2.5px;
  /* margin: 0.25rem 0; */

  color: var(--white);
  opacity: 0.8;
  text-decoration: underline;
  text-transform: uppercase;

  cursor: pointer;

  :hover {
    opacity: 1;
  }

  @media (max-width: 500px) {
    /* border: 1px solid var(--white); */
    /* padding: 1rem 3rem; */
  }
`;

export const ImgCounter = styled.div``;
