import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 80vh;
  padding: 0 7.5vw;

  z-index: 0;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 25px;
    max-width: 400px;
  }
`;

export const Name = styled.div`
  display: flex;
  max-width: 400px;

  @media (max-width: 400px) {
    display: block;
  }
`;

export const Status = styled.div`
  max-width: 400px;
  padding: 1rem 0;

  a {
    color: var(--white);
  }
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: var(--white);
  opacity: 0.3;
  font-size: 10px;

  a {
    color: var(--white);
    text-decoration: none;
  }
`;
