import styled from "styled-components";

export const ImageContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 66.66%;

  @media (max-width: 500px) {
    padding-bottom: 66.66%;
  }
`;

export const IMG = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
`;

export const Caption = styled.p`
  /* min-height: 45px; */
  text-align: left;
  font-size: 0.85rem;
  line-height: 1.2;
  letter-spacing: 0.5px;
  padding: 0.75rem calc(85px + 1em) 0 40px;
  margin-bottom: 1rem;

  color: rgba(255, 255, 255, 0.5);

  @media (max-width: 500px) {
    padding: 0.5rem 7.5vw;
  }
`;
