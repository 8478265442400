import React, { useState, useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import InputField from "./InputField";
import { Container, Name, Status, Footer } from "./styles";

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      firstName &&
      lastName &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
        MERGE1: firstName,
        MERGE2: lastName,
      });
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
  };

  return (
    <Container>
      {status === "success" ? (
        <h2>Thank you for your support!</h2>
      ) : (
        <>
          <h2>
            Subscribe to the <br />
            M12 STUDIO Newsletter
          </h2>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Name>
              <InputField
                label="First Name"
                onChangeHandler={setFirstName}
                type="text"
                value={firstName}
                placeholder="First Name"
                isRequired
              />

              <InputField
                label="Last Name"
                onChangeHandler={setLastName}
                type="text"
                value={lastName}
                placeholder="Last Name"
                isRequired
              />
            </Name>

            <InputField
              label="Email"
              onChangeHandler={setEmail}
              type="email"
              value={email}
              placeholder="Email"
              isRequired
            />

            <InputField
              label="subscribe"
              type="submit"
              formValues={[email, firstName, lastName]}
            />
          </form>
        </>
      )}

      {status === "sending" && <Status>sending...</Status>}
      {status === "error" && (
        <Status dangerouslySetInnerHTML={{ __html: message }} />
      )}
    </Container>
  );
};

const Mailchimp = (props) => {
  const postURL = `https://m12studio.us1.list-manage.com/subscribe/post?u=6e8702eb7c38e16e53bfa1020&id=ce0eee11dc`;

  return (
    <div>
      <MailchimpSubscribe
        url={postURL}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
      <Footer>
        &copy; 2021{" "}
        <a href="https://m12studio.org/" target="_blank" rel="noreferrer">
          M12 STUDIO
        </a>
      </Footer>
    </div>
  );
};

export default Mailchimp;
