import React, { useState, useRef, useEffect } from "react";
import { SongTitle, SongArtist, Button, Container } from "./styles";

import { FaPlay, FaPause } from "react-icons/fa";

const SoundComponent = ({ project }) => {
  console.log();

  const artist = project.properties.songs.artist;
  const song = project.properties.songs.song;
  const src = project.properties.songs.src;

  const audioEl = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      audioEl.current.play();
    } else {
      audioEl.current.pause();
    }
  });

  const PlayButton = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <Container>
      <Button onClick={() => PlayButton()}>
        {isPlaying ? <FaPause /> : <FaPlay />}
      </Button>
      <SongTitle>"{song}"</SongTitle> by <SongArtist>{artist}</SongArtist>
      <audio src={src} ref={audioEl}></audio>
    </Container>
  );
};

export default SoundComponent;
