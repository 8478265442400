import React from "react";
import { Label, Input, Submit } from "./styles";

const InputField = (props) => {
  const validateInput = (values) => {
    if (values.some((f) => f === "") || values[0].indexOf("@") === -1) {
      return true;
    } else {
      return false;
    }
  };

  if (props.type === "submit") {
    return (
      <Submit
        className="submit__button"
        type="submit"
        value={props.label}
        disabled={validateInput(props.formValues)}
      />
    );
  } else if (props.type === "textarea") {
    return (
      <Label>
        {/* {props.label} */}
        <textarea
          onChange={(e) => props.onChangeHandler(e.target.value)}
          value={props.value}
          required={props.isRequired}
          className="inputField__field"
          rows={7}
          name={props.name}
          placeholder={props.placeholder}
        />
      </Label>
    );
  } else {
    return (
      <Label>
        {/* {props.label} */}
        <Input
          onChange={(e) => props.onChangeHandler(e.target.value)}
          type={props.type}
          value={props.value}
          required={props.isRequired}
          className="inputField__field"
          name={props.name}
          placeholder={props.placeholder}
        />
      </Label>
    );
  }
};

export default React.memo(InputField);
