import React, { useState, useEffect } from "react";
import Slide from "./Slide";
import Modal from "../Modal";

import { SlideContainer, ButtonContainer, Button, ImgCounter } from "./styles";

const Slideshow = ({ project }) => {
  const images = project.properties.images;

  console.log();

  // Determine which slide is showing
  const [current, setCurrent] = useState(0);

  // Number of Slides, images and sound
  const length = images.length;

  // Set modal state as closed
  const [isOpen, setIsOpen] = useState(false);

  // Reset slideshow to first slide when project changes
  useEffect(() => {
    setCurrent(0);
  }, [images]);

  // Functions to advance and retreat slides
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  // If the project only has one image, do not render slideshow
  if (!Array.isArray(images) || images.length <= 0) {
    return (
      <>
        <SlideContainer onClick={() => setIsOpen(true)}>
          <Slide project={project} image={images} />
        </SlideContainer>
        <Modal images={images} open={isOpen} onClose={() => setIsOpen(false)} />
      </>
    );
  }

  return (
    <>
      <SlideContainer onClick={() => setIsOpen(true)}>
        {images.map((image, index) => {
          return (
            <React.Fragment key={index}>
              {index === current && <Slide project={project} image={image} />}
            </React.Fragment>
          );
        })}
      </SlideContainer>

      <Modal
        open={isOpen}
        current={current}
        images={images}
        onClose={() => setIsOpen(false)}
        prev={prevSlide}
        next={nextSlide}
      />

      <ButtonContainer>
        <Button onClick={() => nextSlide()}>Next</Button>
        <ImgCounter>
          {current + 1}&thinsp;/&thinsp;{length}
        </ImgCounter>
        <Button onClick={() => prevSlide()}>Prev</Button>
      </ButtonContainer>
    </>
  );
};

export default Slideshow;
