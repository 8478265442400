import styled from "styled-components";

export const Container = styled.section`
  display: block;
  margin-bottom: 1rem;

  font-size: 0.85rem;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.5);
`;

export const SongTitle = styled.h3`
  font-style: italic;
  display: inline-block;
  font-weight: 400;
`;

export const SongArtist = styled.h3`
  display: inline-block;
  font-weight: 400;
`;

export const Button = styled.button`
  font-size: 10px;
  background-color: black;
  border: none;
  border-radius: 5px;
  margin-right: 1rem;
  color: rgba(255, 255, 255, 0.5);
  padding: 6px 7px 4px 6px;
  grid-area: 1 / 1;
`;
